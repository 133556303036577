<script setup lang="ts">
import { useFelixStore } from "@/store/felix";
import { useCompanyInformation } from "@/composables/use-company-information";
import { storeToRefs } from "pinia";

const route = useRoute();
const felixStore = useFelixStore();
const { hasCompanyInformation, hasBillingInformation } = useCompanyInformation();
const { hasUnreadMessages, conversations, fetching, hiddenRoutes, chatOpen } = storeToRefs(felixStore);

const hasConversations = computed(() => conversations.value && conversations.value.length > 0);
const showNotification = computed(
  () =>
    hasCompanyInformation.value &&
    hasBillingInformation.value &&
    (hasUnreadMessages.value || (!hasConversations.value && !fetching.value))
);

const isHidden = computed(() => hiddenRoutes.value.includes(route.path));
</script>

<template>
  <div class="fixed bottom-4 right-4 z-50" v-if="!isHidden">
    <button
      @click="felixStore.toggleChat"
      class="bg-primary text-white p-1 rounded-full border ring-1 ring-offset-0 ring-white hover:ring-offset-primary hover:ring-2 hover:ring-offset-2 shadow-xl relative"
      :class="{ 'animate-bounce': showNotification && !chatOpen }"
    >
      <div
        v-if="showNotification && !chatOpen"
        class="absolute top-0 right-1 w-3 h-3 bg-danger border border-white rounded-full"
      />
      <FelixLogo :shadow="false" class="w-10 h-10" />
    </button>
  </div>
</template>
