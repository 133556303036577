<script setup lang="ts">
import { useFelixStore } from "@/store/felix";
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from "@headlessui/vue";
import FelixFloatingIcon from "@/components/dashboard/felix/FelixFloatingIcon.vue";

const felixStore = useFelixStore();
const { chatOpen } = storeToRefs(felixStore);
</script>

<template>
  <TransitionRoot appear :show="chatOpen" as="template">
    <Dialog as="div" @close="felixStore.closeChat" class="relative z-40">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-300 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden pointer-events-none">
        <div class="absolute bottom-10 md:right-10 xl:right-10 pointer-events-auto p-2 md:p-0 w-full sm:w-fit">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="ease-in-out duration-300"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <DialogPanel class="flex flex-col bg-white shadow-xl rounded-lg w-full md:w-[90vw] h-[90vh] max-h-[800px]">
              <slot></slot>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
      <FelixFloatingIcon @click="felixStore.closeChat"/>
    </Dialog>
  </TransitionRoot>
</template>
