<script setup lang="ts">
import type { Job } from '@/dto/job/job';
import { useFelixStore } from '@/store/felix';

interface Props {
  job?: Job;
  variant?: 'inline' | 'inline-small';
}

interface Emits {
  (e: 'action', prompt: string): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const felixStore = useFelixStore();

const quickActions = [
  {
    id: 'analyze',
    label: 'Analyze this job',
    icon: 'heroicons:document-magnifying-glass-20-solid',
    description: 'Get a detailed analysis of the job posting',
    prompt: (title: string) => `Please provide a detailed analysis of my job posting "${title}".`
  },
  {
    id: 'improve',
    label: 'Suggest improvements',
    icon: 'heroicons:light-bulb-20-solid',
    description: 'Get suggestions to improve the job posting',
    prompt: (title: string) => `What suggestions do you have to improve my job posting "${title}"?`
  },
  {
    id: 'feedback',
    label: 'Get feedback',
    icon: 'heroicons:chat-bubble-left-right-20-solid',
    description: 'Get feedback on the job posting',
    prompt: (title: string) => `Please review my job posting "${title}" and provide detailed feedback.`
  }
];

const handleAction = async (action: string) => {
  const quickAction = quickActions.find(a => a.id === action);
  if (!quickAction || !props.job?.title) return;
  
  const prompt = quickAction.prompt(props.job.title);
  await felixStore.processJobConversation({ job: props.job, userPrompt: prompt });
};
</script>

<template>
  <div :class="[
    'flex flex-wrap gap-2',
    variant === 'inline' ? 'justify-center' : variant === 'inline-small' ? '' : ''
  ]">
    <button
      v-for="action in quickActions"
      :key="action.id"
      :class="[
        'inline-flex items-center gap-1 rounded-full bg-white hover:bg-light-gray text-dark-gray border transition-colors',
        variant === 'inline' ? 'px-3 py-1 text-sm font-medium' : 
        variant === 'inline-small' ? 'px-2 py-1 text-xs font-medium' :
        'p-2 text-left rounded-lg w-full'
      ]"
      @click="handleAction(action.id)"
    >
      <Icon 
        :name="action.icon" 
        :class="[
          variant === 'inline' ? 'w-4 h-4' :
          variant === 'inline-small' ? 'w-3 h-3' :
          'w-5 h-5 text-gray-400'
        ]" 
      />
      <span>{{ action.label }}</span>
      <span v-if="!variant" class="text-sm text-medium-gray truncate block">{{ action.description }}</span>
    </button>
  </div>
</template>