<script setup lang="ts">
interface Props {
  disabled?: boolean;
}

interface Emits {
  (e: 'send', message: string): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const userInput = ref('');
const textareaRef = ref<HTMLTextAreaElement>();

const adjustTextareaHeight = () => {
  if (!textareaRef.value) return;
  textareaRef.value.style.height = 'auto';
  const maxHeight = 24 * 4;
  const scrollHeight = Math.min(textareaRef.value.scrollHeight, maxHeight);
  textareaRef.value.style.height = `${scrollHeight}px`;
};

const handleSend = () => {
  if (!userInput.value.trim() || props.disabled) return;
  
  const message = userInput.value.trim();
  userInput.value = '';
  adjustTextareaHeight();
  emit('send', message);
};
</script>

<template>
  <div class="px-4 py-3 border-t border-light-gray">
    <div class="flex items-center gap-2">
      <textarea
        ref="textareaRef"
        v-model="userInput"
        rows="1"
        class="flex-1 resize-none border-0 bg-transparent p-0 focus:ring-0 text-sm placeholder:text-medium-gray disabled:opacity-50 text-dark-gray"
        placeholder="Type your message..."
        @input="adjustTextareaHeight"
        @keydown.enter.prevent="handleSend"
        :disabled="disabled"
      />
      <button 
        class="group p-2 mr-10 bg-primary hover:bg-primary-light disabled:bg-primary rounded-full flex items-center justify-center disabled:opacity-50"
        @click="handleSend"
        :disabled="disabled || !userInput.trim()"
      >
        <Icon name="heroicons:paper-airplane-20-solid" class="w-5 h-5 text-white " />
      </button>
    </div>
  </div>
</template> 