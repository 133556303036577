<script setup lang="ts">
import { useCompanyStore } from "@/store/company";

const companyStore = useCompanyStore();
const {  cantCreateJobModal } = storeToRefs(companyStore);

const closeModal = () => {
  companyStore.toggleCantCreateJobModal({ active: false });
};

const doAction = () => {
  cantCreateJobModal.value.action();
  closeModal();
};
</script>

<template>
  <DialogModal :show="cantCreateJobModal.active" @close="closeModal">
    <template #title> {{ cantCreateJobModal.title }}</template>
    <template #content>
      <p class="mb-4">{{ cantCreateJobModal.description }}</p>
    </template>
    <template #footer>
      <PrimaryButton @click="doAction">
        {{ cantCreateJobModal.buttonText }}
      </PrimaryButton>
      <OutlineButton class="ml-3" @click.prevent="closeModal"> Cancel </OutlineButton>
    </template>
  </DialogModal>
</template>
