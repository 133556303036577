<script setup lang="ts">
import { useFelixStore } from "@/store/felix";
import { useJobStore } from "@/store/job";
import { useEmployerStore } from "@/store/employer";
import { useCompanyStore } from "@/store/company";
import FelixChatModal from "@/components/dashboard/felix/FelixChatModal.vue";
import FelixFloatingIcon from "@/components/dashboard/felix/FelixFloatingIcon.vue";
import FelixJobChatContent from "@/components/dashboard/felix/contexts/job/FelixJobChatContent.vue";
import FelixConversationList from "@/components/dashboard/felix/FelixConversationList.vue";
import FelixJobSelector from "@/components/dashboard/felix/contexts/job/FelixJobSelector.vue";
import type { FelixConversation } from "@/types/felix";
import type { Job } from "@/dto/job/job";

const felixStore = useFelixStore();
const jobStore = useJobStore();
const employerStore = useEmployerStore();

const { conversations, fetching, selectedConversationId } = storeToRefs(felixStore);
const { jobs } = storeToRefs(jobStore);
const { employer } = storeToRefs(employerStore);
const { company } = storeToRefs(useCompanyStore());


const selectedJob = ref<Job | null>(null);
const showMobileChat = ref(false);

const { status } = await useLazyAsyncData("conversations", async () => {
  await felixStore.fetchConversations();
});

const isFetching = computed(() => fetching.value || status.value === "pending");

const showFelixIcon = computed(() => employer.value?.onboarding_seen);

const handleJobSelect = (job: Job) => {
  selectedJob.value = job;

  const conversation = felixStore.findConversationByIdentifier(felixStore.getJobUniqueIdentifier(job.id));
  selectedConversationId.value = conversation?.id;

  if (!conversation) {
    showMobileChat.value = true;
  }
};

const handleConversationSelect = (conversation: FelixConversation) => {
  if (!conversation.unique_identifier) return;
  selectedConversationId.value = conversation.id;

  const jobId = parseInt(conversation.unique_identifier.replace("job-", ""));
  const job = jobs.value.find((j) => j.id === jobId);

  if (job) {
    selectedJob.value = job;
    showMobileChat.value = true;
  }
};

const handleBack = () => {
  showMobileChat.value = false;
};

const handleConversationDelete = (conversationId: string) => {
  if (selectedConversationId.value === conversationId) {
    selectedJob.value = null;
    selectedConversationId.value = undefined;
    showMobileChat.value = false;
  }
};
</script>

<template>
  <div>
    <FelixFloatingIcon v-if="showFelixIcon" />
    <FelixChatModal>
      <div class="flex h-full w-full">
        <div :class="['h-full w-full md:w-64 flex flex-col border-r border-light-gray', 'md:block', showMobileChat ? 'hidden' : '']">
          <div class="shrink-0 p-4 border-b border-light-gray sm:hidden">
            <div class="flex items-center justify-between mb-4 sm:mb-0">
              <div class="flex items-center gap-2">
                <FelixLogo class="w-8 h-8" />
                <h3 class="text-lg font-medium text-dark-gray">Chat with Felix</h3>
              </div>
              <Icon
                name="fa6-solid:xmark"
                class="w-5 h-5 text-medium-gray hover:text-primary cursor-pointer transition-all hover:rotate-180"
                @click="felixStore.closeChat"
              />
            </div>
            <FelixJobSelector
              class="sm:hidden"
              :selected-job-id="selectedJob?.id"
              @select="handleJobSelect"
              :loading="isFetching"
            />
          </div>
          <div class="h-full flex-1 overflow-hidden">
            <FelixConversationList
              :loading="isFetching"
              :conversations="conversations"
              :selected-conversation-id="selectedConversationId"
              @select="handleConversationSelect"
              @delete="handleConversationDelete"
            />
          </div>
        </div>

        <div :class="['h-full flex-1 flex flex-col', 'md:block', !showMobileChat ? 'hidden' : '']">
          <FelixJobChatContent
            :selected-job="selectedJob"
            :show-back-button="true"
            @select-job="handleJobSelect"
            @back="handleBack"
          />
        </div>
      </div>
    </FelixChatModal>
  </div>
</template>
