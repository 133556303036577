<script setup lang="ts">
import { useJobStore } from "@/store/job";
import type { Job } from "@/dto/job/job";
import { computed } from "vue";
import type { SelectItem } from "@/types";

interface Props {
  selectedJobId?: number;
  loading?: boolean;
}

interface Emits {
  (e: "select", job: Job): void;
}

const emit = defineEmits<Emits>();
defineProps<Props>();

const jobStore = useJobStore();
const { jobs } = storeToRefs(jobStore);
const selectedJob = ref<SelectItem | null>(null);

const availableJobsList = computed(() => {
  return jobs.value.map((job) => ({ id: job.id, name: job.title }));
});

watch(selectedJob, (newVal: SelectItem | null) => {
  const chosenJob = jobs.value.find((job) => job.id === newVal?.id);
  if (chosenJob) emit("select", chosenJob);
});
</script>

<template>
  <div class="relative w-full">
    <ComboBox
      name="job-list"
      :items="availableJobsList"
      v-model="selectedJob"
      :disabled="loading || !jobs.length"
      validate-as="required:Job"
      placeholder="Select or search for a job"
    />
  </div>
</template>
