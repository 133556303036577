<script setup lang="ts">
import { useFelixStore } from "@/store/felix";
import { useJobStore } from "@/store/job";
import type { Job } from "@/dto/job/job";
import FelixJobSelector from "@/components/dashboard/felix/contexts/job/FelixJobSelector.vue";
import FelixJobQuickActions from "@/components/dashboard/felix/contexts/job/FelixJobQuickActions.vue";
import FelixChatInput from "@/components/dashboard/felix/FelixChatInput.vue";
import { marked } from "marked";
import type { FelixMessage } from "@/types/felix";

interface Props {
  selectedJob: Job | null;
  showBackButton?: boolean;
}

interface Emits {
  (e: "select-job", job: Job): void;
  (e: "create-job"): void;
  (e: "back"): void;
}

interface MessageWithType extends FelixMessage {
  type?: "error";
  initial?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const jobStore = useJobStore();
const felixStore = useFelixStore();
const { isLoading, chatOpen, selectedConversationId, conversations, felixError } = storeToRefs(felixStore);
const { jobs, fetching } = storeToRefs(jobStore);

const currentConversation = computed(() => {
  if (!selectedConversationId.value) return null;
  return conversations.value.find((c) => c.id === selectedConversationId.value);
});

const showInitialState = computed(() => {
  return !currentConversation.value;
});

const messages = computed(() => {
  return (currentConversation.value?.messages || []) as MessageWithType[];
});

const messageGroups = computed(() => {
  return { regular: messages.value };
});

const lastMessageIsFelix = computed(() => {
  const messages = currentConversation.value?.messages || [];
  const lastMessage = messages[messages.length - 1];
  return messages.length > 0 && lastMessage.role === FELIX_MESSAGE_ROLE.assistant && !lastMessage.is_loading;
});

const scrollToBottom = () => {
  nextTick(() => {
    const messages = currentConversation.value?.messages || [];
    if (messages.length === 0) return;

    const latestMessageId = `message-${messages.length}`;
    const latestMessage = document.getElementById(latestMessageId);
    if (latestMessage) latestMessage.scrollIntoView({ block: "start" });
  });
};

const handleSendMessage = async (message: string) => {
  if (!props.selectedJob) return;

  await felixStore.processJobConversation({
    job: props.selectedJob,
    userPrompt: message,
  });
};

const createJob = () => {
  felixStore.closeChat();
  setTimeout(() => {
    createJobWithCheck();
  }, 300);
};

watch(
  () => props.selectedJob,
  (job) => {
    if (!job) {
      selectedConversationId.value = undefined;
      return;
    }

    const conversation = felixStore.findConversationByIdentifier(felixStore.getJobUniqueIdentifier(job.id));
    selectedConversationId.value = conversation?.id;
  },
  { immediate: true }
);

const shouldMarkAsRead = computed(() => chatOpen.value && selectedConversationId.value);

watch(
  shouldMarkAsRead,
  (shouldMark) => {
    if (shouldMark && selectedConversationId.value) {
      felixStore.markConversationAsRead(selectedConversationId.value);
    }
  },
  { immediate: true }
);

watch(() => currentConversation.value?.messages, scrollToBottom, { deep: true });
</script>

<template>
  <div class="flex flex-col h-full relative">
    <div class="flex items-center justify-between p-4 border-b border-light-gray">
      <div class="flex items-center gap-2 flex-shrink-0 sm:mr-5">
        <button @click="$emit('back')" class="md:hidden p-1 hover:bg-gray-100 rounded-full flex">
          <Icon name="fa6-solid:arrow-left" class="w-4 h-4 text-medium-gray" />
        </button>
        <FelixLogo class="w-8 h-8" />
        <h3 class="text-lg font-medium text-dark-gray">Chat with Felix</h3>
      </div>
      <div class="flex items-center gap-2 sm:w-3/4">
        <FelixJobSelector
          class="hidden sm:block"
          :selected-job-id="selectedJob?.id"
          @select="$emit('select-job', $event)"
          :loading="isLoading"
        />
        <Icon
          name="fa6-solid:xmark"
          class="w-5 h-5 text-medium-gray hover:text-primary cursor-pointer transition-all hover:rotate-180"
          @click="felixStore.closeChat"
        />
      </div>
    </div>

    <div class="flex-1 flex overflow-hidden relative">
      <div ref="chatContainer" class="flex-1 overflow-y-auto px-4 pt-4 pb-4">
        <div v-if="fetching" class="absolute inset-0 flex items-center justify-center flex-col gap-4">
          <div class="p-3 rounded-lg max-w-[85%] mx-auto text-center">
            <div class="prose prose-sm sm:prose-base">
              <Icon name="svg-spinners:3-dots-move" class="h-12 w-12 text-primary" />
            </div>
          </div>
        </div>

        <div
          v-else-if="!jobs?.length && !fetching"
          class="absolute inset-0 flex items-center justify-center flex-col gap-4"
        >
          <div class="p-3 rounded-lg max-w-[85%] mx-auto text-center bg-white-gray text-dark-gray">
            Please create a job to start chatting with Felix.
          </div>

          <PrimaryButton secondary @click="createJob">Create Job Post</PrimaryButton>
        </div>

        <div v-else-if="showInitialState" class="absolute inset-0 flex items-center justify-center flex-col gap-4">
          <div class="p-3 rounded-lg max-w-[85%] mx-auto text-center bg-white-gray text-dark-gray">
            {{
              selectedJob
                ? `I'm ready to help you with "${selectedJob.title}". What would you like to know?`
                : "Hi! I'm Felix. Select a job to get started, and I'll help you with any job posting."
            }}
          </div>
          <div v-if="selectedJob" class="max-w-[85%] mx-auto">
            <FelixJobQuickActions :job="selectedJob" variant="inline" />
          </div>
        </div>

        <div v-else class="flex flex-col h-full">
          <div class="flex-1"></div>
          <div class="flex flex-col space-y-4 pb-4">
            <template v-for="(message, index) in messageGroups.regular" :key="message.timestamp">
              <div
                :id="`message-${index + 1}`"
                :class="[
                  'p-3 rounded-lg lg:max-w-[85%] scroll-mt-4 w-fit transition-all box-border',
                  message.role === FELIX_MESSAGE_ROLE.assistant
                    ? [
                        'bg-white-gray ml-0',
                        !message.read_at && !message.is_loading && 'ring-1 ring-primary shadow-lg',
                      ]
                    : 'bg-primary text-white ml-auto',
                ]"
              >
                <div v-if="message.role === FELIX_MESSAGE_ROLE.assistant" class="prose prose-sm sm:prose-base">
                  <div v-if="message.is_loading" class="flex items-center justify-center w-12">
                    <Icon name="svg-spinners:3-dots-move" class="h-6 w-6 text-primary" />
                  </div>
                  <div v-else v-dompurify-html="marked(message.content)" />
                </div>
                <div v-else>{{ message.content }}</div>
                <div
                  v-if="message.timestamp && !message.is_loading"
                  :class="[
                    'mt-1 text-xs',
                    message.role === FELIX_MESSAGE_ROLE.assistant ? 'text-medium-gray' : 'text-light-gray',
                  ]"
                >
                  {{ formatChatMessageTime(message.timestamp) }}
                </div>
              </div>

              <div
                v-if="felixError?.showInChat && index === messageGroups.regular.length - 1"
                class="p-3 w-fit rounded-lg max-w-[85%] bg-danger/10 ml-0"
              >
                <div class="flex items-center justify-center gap-2">
                  <Icon name="fa6-solid:circle-exclamation" class="h-4 w-4 text-danger" />
                  <p class="text-danger">{{ felixError.message }}</p>
                </div>
                <div class="mt-1 text-xs text-danger">
                  {{ formatChatMessageTime(new Date().toISOString()) }}
                </div>
              </div>
            </template>

            <div v-if="selectedJob && lastMessageIsFelix" class="mt-4">
              <FelixJobQuickActions :job="selectedJob" variant="inline-small" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <FelixChatInput v-if="selectedJob" :disabled="isLoading" @send="handleSendMessage" />
  </div>
</template>
