<script setup lang="ts">
import type { FelixConversation } from '@/types/felix';
import { useJobStore } from '@/store/job';
import { storeToRefs } from 'pinia';

interface Props {
  conversation: FelixConversation;
  isSelected: boolean;
}

interface Emits {
  (e: 'select', conversation: FelixConversation): void;
  (e: 'delete', conversationId: string): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const jobStore = useJobStore();
const { jobs, fetching } = storeToRefs(jobStore);

const getPreview = (conversation: FelixConversation) => {
  if (!conversation.unique_identifier) return 'New Conversation';
  
  const jobId = parseInt(conversation.unique_identifier.replace('job-', ''));
  const job = jobs.value.find(j => j.id === jobId);
  
  return job ? job.title : 'Job Conversation';
};

const hasUnreadMessages = computed(() => {
  return props.conversation.messages.some(msg => 
    msg.role === FELIX_MESSAGE_ROLE.assistant && !msg.read_at && !msg.is_loading
  );
});

const nonLoadingMessages = computed(() => {
  return props.conversation.messages.filter(msg => !msg.is_loading).length;
});
</script>

<template>
  <div
    class="group p-4 rounded-lg border cursor-pointer transition-colors relative"
    :class="[
      isSelected
        ? 'border-primary bg-gray-50'
        : 'border-gray-200 hover:bg-gray-50',
    ]"
    @click="emit('select', conversation)"
  >
    <div class="flex flex-col gap-y-2 items-start mb-2">
      <div class="flex items-center gap-2">
        <div 
          v-if="hasUnreadMessages" 
          class="w-3 h-3 rounded-full bg-primary absolute top-2 right-2"
          
        />
        <div v-if="!fetching" class="text-sm font-medium line-clamp-1">
          {{ getPreview(conversation) }}
        </div>
        <div v-else class="text-sm font-medium line-clamp-1">
          <Icon name="svg-spinners:3-dots-move" class="w-4 h-4 text-gray-500" />
        </div>
      </div>
      <div class="text-xs text-gray-500">
        {{ formatChatMessageTime(conversation.last_message_at) }}
      </div>
    </div>
    <div class="text-xs text-gray-500">{{ nonLoadingMessages }} messages</div>

    <div class="absolute right-2 top-2 opacity-0 group-hover:opacity-100 transition-opacity" @click.stop>
      <DropdownMenu>
        <template #dropdown-button>
          <div class="p-1 bg-gray-200 hover:bg-gray-300 rounded-md h-5 w-5 flex items-center justify-center">
            <span class="sr-only">Open conversation menu</span>
            <Icon
              name="heroicons:ellipsis-horizontal-20-solid"
              class="w-4 h-4 text-gray-500"
            />
          </div>
        </template>
        <template #dropdown-items>
          <DropdownItem @click="emit('delete', conversation.id)">Delete</DropdownItem>
        </template>
      </DropdownMenu>
    </div>
  </div>
</template> 