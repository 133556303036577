<script setup lang="ts">
import type { FelixConversation } from '@/types/felix';
import FelixConversationCard from '@/components/dashboard/felix/FelixConversationCard.vue';
import { useFelixStore } from '@/store/felix';

interface Props {
  conversations: FelixConversation[];
  loading?: boolean;
  selectedConversationId?: string;
}

interface Emits {
  (e: 'select', conversation: FelixConversation): void;
  (e: 'delete', conversationId: string): void;
}

defineProps<Props>();
const emit = defineEmits<Emits>();
const felixStore = useFelixStore();

const handleDelete = async (conversationId: string) => {
  emit('delete', conversationId);
  await felixStore.deleteConversation(conversationId);
};
</script>

<template>
  <div class="h-full flex flex-col">
    <div v-if="!loading && conversations.length === 0" 
         class="h-full flex items-center justify-center text-sm text-medium-gray text-center">
      No conversations yet
    </div>

    <div v-else-if="loading" 
         class="h-full overflow-y-auto">
      <div class="space-y-2 p-2">
        <div v-for="i in 5" :key="i" class="animate-pulse">
          <div class="h-24 bg-light-gray rounded-lg"></div>
        </div>
      </div>
    </div>

    <div v-else 
         class="h-full overflow-y-auto">
      <div class="space-y-2 p-2">
        <FelixConversationCard
          v-for="conversation in conversations"
          :key="conversation.id"
          :conversation="conversation"
          :is-selected="conversation.id === selectedConversationId"
          @select="emit('select', conversation)"
          @delete="handleDelete"
        />
      </div>
    </div>
  </div>
</template>